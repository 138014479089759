import styled, { css } from 'styled-components';

const Text14 = styled.div`
font-family: ${({ font }) => font ? font : 'InterRegular'} , sans-serif;
  font-size: 14px;
  line-height:${({ height }) => height ? height : 16}px;
  text-align: ${({ align }) => align ? align : 'left'};
  color: ${({ color }) => color ? color : 'black'};
  column-count:${({ withCols }) => withCols ? 2 : 1};
  font-weight:normal;

  ${({ maxWidth }) => maxWidth && css`
    max-width: ${maxWidth};
  `}
  
  b{
    font-family: 'InterBold', sans-serif;
  }
  
  strong{
    font-family: 'InterBold' , sans-serif;
  }

  a{
    color:#6F6F6E;
    :hover{
      cursor: pointer;
      color:#E3000F;
    }
  }

  p {
    margin-bottom: 8px;
    :last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 768px) {
    column-count:1;
  }
`;

export default Text14;
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Container, H2, H4, H5, H6, Section, Text12, Text14, Text16, Text18, Wrapper } from '../../atoms';
import { ModalCaseStudies } from '../../molecules';
import { gql, useLazyQuery } from "@apollo/client";

export default function CaseStudies({ titleCase, textMore }) {

  const [arrCase, setArrCase] = useState([]);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [sliderIndexModal, setSliderIndexModal] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [contentModal, setContentModal] = useState(null);

  const [getCaseStudys] = useLazyQuery(
    gql`
        query frontendCaseStudies($first: Int){
          frontendCaseStudies(first: $first){
            edges{
              node{
                id
                content
              }
            }
          }
        }
    `,
    {
      fetchPolicy: 'network-only',
      onError: (error) => { console.log(error) },
      onCompleted: (data) => {
        if (data?.frontendCaseStudies?.edges && data?.frontendCaseStudies?.edges?.length > 0) {
          const newArray = data.frontendCaseStudies.edges.map(x =>
            x.node.content.sectionkey
          );
          setArrCase(newArray);
        }
      }
    }
  );

  const toggleModal = (content) => {
    setOpenModal(true);
    setContentModal(content);
  };

  useEffect(() => {
    if (openModal === false) {
      setContentModal(null);
      setSliderIndexModal(0);
    }
  }, [openModal]);

  useEffect(() => {
    getCaseStudys({ variables: { first: 100 } });
  }, []);

  return (
    <>
      <Section backgroundColor="#fff" >
        <Wrapper both noHeight >
          <Container md>
            <TitleCase src='/static/images/casestudies.svg'/>
            <DivRow>
              {(arrCase && arrCase.length > 0) &&
                <>
                  <ContainerSlider>
                    {arrCase.map((study, index) => (
                      <ImageItem key={String(index)} className={index === sliderIndex ? 'show' : ''} src={study.img?.url} />
                    ))}
                    <DivText >
                      {arrCase.map((study, index) => (
                        <Text key={String(index)} className={index === sliderIndex ? 'show' : ''}>
                          <Text12 color="#368D67" style={{ textTransform: 'uppercase' }} font="InterBold" dangerouslySetInnerHTML={{ __html: titleCase }} />
                          <Margin margin={1.5} />
                          <H5 color="#35383B" height={28} maxWidth="215px" dangerouslySetInnerHTML={{ __html: study.title }} />
                          <Margin margin={2} />
                          <Text18 color="#fff" font="InterMedium" maxWidth="258px" dangerouslySetInnerHTML={{ __html: (study.description.length > 102) ? study.description.slice(0, 102) + "..." : study.description }} />
                          <Margin margin={3} />
                          <ButtonCase onClick={() => toggleModal(study)}>
                            <Text14 color="#368D67" style={{ textTransform: 'capitalize' }} maxWidth="258px" dangerouslySetInnerHTML={{ __html: textMore }} />
                            <Icon src="/static/images/arrow_right.svg" />
                          </ButtonCase>
                        </Text>
                      ))}
                    </DivText>
                  </ContainerSlider>
                  <DivColumnArrows>
                    <Numbers height={38} dangerouslySetInnerHTML={{ __html: '0' + (sliderIndex + 1) }} />
                    <Line />
                    <Numbers height={38} color="#B2B6B9" dangerouslySetInnerHTML={{ __html: '0' + (arrCase.length) }} />
                    <Margin margin={5} />
                    <ButtonNextPrev onClick={() => sliderIndex > 0 ? setSliderIndex(sliderIndex - 1) : null}>
                      <IconChevron src="/static/images/chevron.svg" />
                    </ButtonNextPrev>
                    <ButtonNextPrev onClick={() => sliderIndex >= arrCase.length - 1 ? null : setSliderIndex(sliderIndex + 1)}>
                      <IconChevron style={{ transform: 'rotate(180deg)' }} src="/static/images/chevron.svg" />
                    </ButtonNextPrev>
                  </DivColumnArrows>
                </>
              }
            </DivRow>
          </Container>
        </Wrapper>
      </Section>
      <ModalCaseStudies isOpen={openModal}>
        <>
          <ButtonClose onClick={() => setOpenModal(false)}>
            <img src="/static/images/icon_close.svg" />
          </ButtonClose>
          <Container lg>
            <Wrapper both noHeight>
              {contentModal !== null &&
                <ContainerModal>
                  <H2 height="56px" color="#35383B" maxWidth="960px" dangerouslySetInnerHTML={{ __html: contentModal.title }} />
                  {contentModal.description &&
                    <>
                      <Margin margin={3} />
                      <H6 color="#B2B6B9" maxWidth="960px" dangerouslySetInnerHTML={{ __html: contentModal.description }} />
                    </>
                  }
                  {(contentModal.arrImg && contentModal.arrImg.length > 0) &&
                    <>
                      <Margin margin={7} />
                      <DivRow>
                        <ContainerSlider modal={true}>
                          {contentModal?.arrImg.map((sliderImage, index) => (
                            <ImageItem key={String(index)} className={index === sliderIndexModal ? 'show' : ''} src={sliderImage.img?.url} />
                          ))}
                        </ContainerSlider>
                        <DivColumnArrows>
                          <Numbers height={38} dangerouslySetInnerHTML={{ __html: '0' + (sliderIndexModal + 1) }} />
                          <Line />
                          <Numbers height={38} color="#B2B6B9" dangerouslySetInnerHTML={{ __html: '0' + (contentModal.arrImg.length) }} />
                          <Margin margin={5} />
                          <ButtonNextPrev onClick={() => sliderIndexModal > 0 ? setSliderIndexModal(sliderIndexModal - 1) : null}>
                            <IconChevron src="/static/images/chevron.svg" />
                          </ButtonNextPrev>
                          <ButtonNextPrev onClick={() => sliderIndexModal >= contentModal.arrImg.length - 1 ? null : setSliderIndexModal(sliderIndexModal + 1)}>
                            <IconChevron style={{ transform: 'rotate(180deg)' }} src="/static/images/chevron.svg" />
                          </ButtonNextPrev>
                        </DivColumnArrows>
                      </DivRow>
                    </>
                  }
                  {contentModal.secondTitle &&
                    <>
                      <Margin margin={9} />
                      <H6 color="#35383B" dangerouslySetInnerHTML={{ __html: contentModal.secondTitle }} />
                    </>
                  }
                  {contentModal.text &&
                    <>
                      <Margin margin={2} />
                      <TextExplanation color="#B2B6B9" maxWidth="764px" dangerouslySetInnerHTML={{ __html: contentModal.text }} />
                    </>
                  }
                  {contentModal.thirdTitle &&
                    <>
                      <Margin margin={9} />
                      <H6 color="#35383B" dangerouslySetInnerHTML={{ __html: contentModal.thirdTitle }} />
                    </>
                  }
                  {(contentModal.list && contentModal.list.length > 0) &&
                    <>
                      <Margin margin={2} />
                      <List>
                        {contentModal.list.map((funcionality, index) => (
                          <li key={String(index)}>
                            <Text16 color="#B2B6B9" dangerouslySetInnerHTML={{ __html: funcionality.text }} />
                          </li>
                        ))}
                      </List>
                    </>
                  }
                </ContainerModal>
              }
            </Wrapper>
          </Container>
        </>
      </ModalCaseStudies>
    </>
  )
}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const TitleCase = styled.img`
  position: relative;
  width: 1024px;
  max-width: 100%;
  max-height: 148px;
  margin-bottom: calc(-8px + (-40 + 8) * ((100vw - 375px) / (1920 - 375)));
  object-fit: contain;
  z-index: 1;
`;
const DivRow = styled.div`
  display: flex;
  flex-flow: row;
  @media screen and (max-width: 600px){
    flex-flow: column;
  }
`;
const ContainerSlider = styled.div`
  position: relative; 
  flex: 1;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: flex-end;
  height: 684px;
  overflow: hidden;
  @media screen and (max-width: 600px){
    flex: unset;
    height: 480px;
  }
  ${({ modal }) => modal && css`
    width: 764px;
    max-width: 100%;
    height: 528px;
    flex: unset;
    @media screen and (max-width: 600px){
      height: 320px;
    }
  `}
`;
const ImageItem = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  &.show{
    opacity: 1;
  }
  @media screen and (max-width:700px){
    height: unset;
  }
`;
const DivText = styled.div`
  display: flex;
  flex-flow: row;
  background-color: #5BB990;
  z-index: 1;
  height: 356px;
  width: 343px;
  padding: 40px 36px 44px 36px;
  box-sizing: border-box;
  position: relative;
  @media screen and (max-width:700px){
    width: 100%;
    height: 280px;
    padding: 24px 18px 24px 20px;
  }
`;
const Text = styled.div`
  position: absolute;
  top: 40px;
  right: 36px;
  bottom: 44px;
  left: 36px;
  display: flex;
  flex-flow: column;
  justify-content:center;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  transform: translateY(100%);
  &.show{
    opacity: 1;
    transform: translateY(0);
  }
  @media screen and (max-width:700px){
    top: 24px;
    right: 18px;
    bottom: 24px;
    left: 20px;
  }
`;
const Icon = styled.img`
  width: 14px;
  height: 12px;
  object-fit: contain;
  margin-left: 8px;
  transition: all 0.4s ease-in-out;
`;
const ButtonCase = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  :hover{
    cursor: pointer;
    ${Icon}{
      margin-left: 12px;
    }
  }
`;
const DivColumnArrows = styled.div`
  display: flex;
  flex-flow: column;
  padding: 0 28px;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width:700px){
    flex-flow: row;
    padding: 28px 0;
  }
`;
const Numbers = styled(H4)`
  margin-bottom: 16px;
  :last-of-type{
    margin-bottom: 40px;
    margin-top: 16px;
  }
  @media screen and (max-width:700px){
    margin-bottom: 0;
    margin-right: 16px;
    :last-of-type{
      margin-bottom: 0;
      margin-top: 0;
      margin-right: 40px;
      margin-left: 16px;
    }
  }
`;
const ButtonNextPrev = styled.div`
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #B2B6B9;
  border-radius: 26px;
  box-sizing: border-box;
  margin-bottom: 8px;
  :last-of-type{
    margin-bottom: 0;
  }
  :hover{
    cursor: pointer;
  }
  @media screen and (max-width:700px){
    margin-bottom: 0;
    margin-right: 8px;
    :last-of-type{
      margin-right: 0;
    }
  }
`;
const IconChevron = styled.img`
  width: 6px;
  height: 12px;
  object-fit: contain;
`;
const Line = styled.div`
  width: 30px;
  height: 1px;
  border-top: 1px solid #B2B6B9;
`;
const ButtonClose = styled.div`
  position: absolute;
  top: 26px;
  left: 26px;
  z-index: 1;
  img{
    width: 24px;
    height: 20px;
    object-fit:contain;
  }
  :hover{
    cursor: pointer;
  }
`;
const ContainerModal = styled.div`
  display: flex;
  flex-flow: column;
`;
const TextExplanation = styled(Text16)`
  p{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  p:last-child{
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
`;
const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li { 
    display: flex;
    flex-flow: row; 
    margin-bottom: 16px;
  }
  li::last-child { 
    margin-bottom: 0;
  }
  li::before {
    content: "•";
    padding-top: 2px;
    padding-right: 8px;
    color: #368D67;
  }
`;
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { H4, H5, Modal, Text12, Text16, Text18 } from '../../atoms';
import { Link } from 'react-router-dom';

export default function ModalMenu(props) {

  const {
    isOpen,
    toogleModal,
    content,
    lang
  } = props;

  const [animation, setAnimation] = useState(false);

  const afterOpenModal = () => {
    setAnimation(true);
  };

  const afterCloseModal = () => {
    setAnimation(false);
  };

  const onRequestClose = () => {
    setAnimation(false);
  };

  useEffect(() => {
    if (isOpen === false) {
      onRequestClose();
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} afterOpenModal={afterOpenModal} afterCloseModal={afterCloseModal}>
      <Overlay animation={animation} />
      <Content animation={animation}>
        <Structure>
          <ButtonModal onClick={() => toogleModal()}>
            <Icon src="/static/images/icon_close_black.svg" />
          </ButtonModal>
          <DivContent>
            <DivRowTop>
              <ItemTop>
                <Link to={`/${lang}${content?.linkMedidata}`}>
                  <H4 color="#35383B" style={{ fontSize: 32 }} font="InterRegular" dangerouslySetInnerHTML={{ __html: content?.titleMedidata }} />
                </Link>
                <Margin margin={2.5} />
                {(content?.listMedidata && content?.listMedidata.length > 0) && content.listMedidata.map((item, index) => (
                  <Link to={`/${lang}${item.link}`} key={String(index)}>
                    <Text16 color="#B2B6B9" height={32} font="InterRegular" dangerouslySetInnerHTML={{ __html: item.title }} />
                  </Link>
                ))}
              </ItemTop>
              <ItemTop>
                <Link to={`/${lang}${content?.linkProdutos}`}>
                  <H4 color="#35383B" style={{ fontSize: 32 }} font="InterRegular" dangerouslySetInnerHTML={{ __html: content?.titleProducts }} />
                </Link>
                <Margin margin={2.5} />
                {(content?.listProducts && content?.listProducts.length > 0) && content.listProducts.map((item, index) => (
                  <Link to={`/${lang}/produtos${item.link}`} key={String(index)}>
                    <Text16 color="#B2B6B9" height={32} font="InterRegular" dangerouslySetInnerHTML={{ __html: item.title }} />
                  </Link>
                ))}
              </ItemTop>
              <ItemTop>
                <Link to={`/${lang}${content?.linkServices}`}>
                  <H4 color="#35383B" style={{ fontSize: 32 }} font="InterRegular" dangerouslySetInnerHTML={{ __html: content?.titleServices }} />
                </Link>
                <Margin margin={3} marginSmall />
                <Link to={`/${lang}${content?.linkNews}`}>
                  <H4 color="#35383B" style={{ fontSize: 32 }} font="InterRegular" dangerouslySetInnerHTML={{ __html: content?.titleNews }} />
                </Link>
                <Margin margin={3} marginBig />
                <Link to={`/${lang}${content?.linkDownloads}`}>
                  <H5 color="#35383B" style={{ fontSize: 24 }} font="InterRegular" dangerouslySetInnerHTML={{ __html: content?.titleDownloads }} />
                </Link>
                <Margin margin={2} />
                <Link to={`/${lang}${content?.linkContacts}`}>
                  <H5 color="#35383B" style={{ fontSize: 24 }} font="InterRegular" dangerouslySetInnerHTML={{ __html: content?.titleContacts }} />
                </Link>
              </ItemTop>
            </DivRowTop>
            <DivRowBottom>
              <DivRowContacts>
                <DivColumn>
                  <DivColumn>
                    <Title color="#35383B" dangerouslySetInnerHTML={{ __html: content?.labelPhone }} />
                    <a href={`tel:${content?.phone}`} target="__blank">
                      <Text18 color="#5D6368" dangerouslySetInnerHTML={{ __html: content?.phone }} />
                    </a>
                    <Text16 color="#5D6368" dangerouslySetInnerHTML={{ __html: content?.labelPhoneBelow }} />
                  </DivColumn>
                  <Margin margin={2.5} />
                  <DivColumn>
                    <Title color="#35383B" dangerouslySetInnerHTML={{ __html: content?.labelEmail }} />
                    <a href={`mailto:${content?.email}`} target="__blank">
                      <Text18 color="#5D6368" dangerouslySetInnerHTML={{ __html: content?.email }} />
                    </a>
                  </DivColumn>
                  <Margin margin={2.5} />
                  <DivColumn>
                    <Title color="#35383B" dangerouslySetInnerHTML={{ __html: content?.labelAddress }} />
                    <Address color="#5D6368" maxWidth="220px" dangerouslySetInnerHTML={{ __html: content?.address }} />
                  </DivColumn>
                </DivColumn>
              </DivRowContacts>
              <Margin margin={4} />
              <DivRowSpace>
                <DivRow>
                  <Text12 color="#5D6368" font="InterRegular" dangerouslySetInnerHTML={{ __html: content?.textRights }} />
                  <DivRow className="legal">
                    <Link to={`/${lang}/legal#politica`} style={{ marginLeft: 16 }}>
                      <Text12 color="#5D6368" font="InterRegular" dangerouslySetInnerHTML={{ __html: content?.textPolicy + " " }} />
                    </Link>
                    <Link to={`/${lang}/legal#cookies`} style={{ marginLeft: 4 }}>
                      <Text12 color="#5D6368" font="InterRegular" dangerouslySetInnerHTML={{ __html: ". " + content?.textCookies }} />
                    </Link>
                  </DivRow>
                </DivRow>
                <DivRow>
                  <Text12 color="#5D6368" font="InterRegular" dangerouslySetInnerHTML={{ __html: content?.titleSocial }} />
                  <ButtonSocial href={content?.linkFace} target="__blank">
                    <IconSocial src="/static/images/icon_facebook.svg" />
                  </ButtonSocial>
                  <ButtonSocial href={content?.linkLinkedin} target="__blank">
                    <IconSocial src="/static/images/icon_linkedin.svg" />
                  </ButtonSocial>
                </DivRow>
              </DivRowSpace>
            </DivRowBottom>
          </DivContent>
        </Structure>
      </Content>
    </Modal>
  )
}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
  ${({ marginSmall }) => marginSmall && css`
    @media screen and (max-width: 750px){
      margin-top: 16px;
    }
  `}
  ${({ marginBig }) => marginBig && css`
    @media screen and (max-width: 750px){
      margin-top: 40px;
    }
  `}

`;
const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  ${({ animation }) => animation && css`
    background-color: rgba(0,0,0,0.5);
  `}
`;
const Structure = styled.div`
  width: 986px;
  max-width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow:column;
  box-sizing: border-box;
  background: #fff;
  padding: 88px 32px 32px 86px;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateX(-100%);
  @media screen and (max-width: 750px){
    padding: 64px 16px 48px 43px;
  }
`;
const Content = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  min-height: 100%;
  display: flex;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  ${({ animation }) => animation && css`
    opacity: 1;
    ${Structure} {
      transform: translateX(0%);
    }
  `};
  @media screen and (max-width: 1200px){
    width:100%;
  }
`;
const ButtonModal = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease-in-out;
  z-index: 1;
  :hover{
    cursor: pointer;
  }
`;
const Icon = styled.img`
  height: 20px;
  width: 20px;
  max-height: 20px;
  max-width: 20px;
  object-fit: contain;
`;
const DivContent = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;
const DivRowTop = styled.div`
  display: flex;
  flex-flow: row;
  @media screen and (max-width: 750px){
    flex-flow: column;
  }
`;
const ItemTop = styled.div`
  display: flex;
  flex-flow: column;
  margin-right: calc(60px + (120 - 60) * ((100vw - 375px) / (1920 - 375)));
  :last-child{
    margin-right: 0;
  }
  @media screen and (max-width: 750px){
    margin-right: 0;
    margin-bottom: 16px;
    :last-child{
      margin-bottom: 32px;
    }
  }
`;
const DivRowBottom = styled.div`
  display: flex;
  flex-flow: column;
`;
const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
`;
const DivRowContacts = styled.div`
  display: flex;
  flex-flow: row;
  > div:first-of-type{
    margin-right: 40px;
  }
  @media screen and (max-width: 750px){
    display: none;
  }
`;
const Title = styled(Text12)`
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-family: 'InterBlack', sans-serif;
`;
const Address = styled(Text18)`
  p:first-child{
    margin-block-start: 0;
    margin-block-end: 4px;
  }
  p:last-child{
    margin-block-start: 0;
    margin-block-end: 0;
  }
  a{
    text-decoration-line: underline;
    color: #368D67;
    font-size: 12px;
    line-height: 14px;
  }
`;
const DivRowSpace = styled.div`
  display: inline-flex;
  justify-content: space-between;
  @media screen and (max-width: 750px){
    flex-flow: column;
  }
`;
const DivRow = styled.div`
  display: inline-flex;
  align-items: center;
  @media screen and (max-width: 750px){
    align-items: center;
    :first-child{
      margin-bottom: 16px;
      flex-flow: column;
      align-items: flex-start;
    }
    .legal{
      flex-flow: row;
      a:first-child{
        margin-left: 0 !important;
      }
    }
  }
`;
const ButtonSocial = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 40px;
  margin-left: 8px;
  background: #D8DADC;
  transition: all 0.4s ease-in-out;
  :hover{
    background: #5D6368;
    cursor: pointer;
  }
`;
const IconSocial = styled.img`
  max-height: 15px;
  max-width: 15px;
  object-fit: contain;
`;
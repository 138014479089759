import { gql, useLazyQuery } from '@apollo/client';
import React, { useEffect, useState, useRef, useContext } from 'react';
import styled, { css } from 'styled-components';
import { store } from '../../../utils/store';
import { Container, Modal, Wrapper, Text12, Section, Text16, H5, H6, Text14, Button } from '../../atoms';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg'

export default function ModalSearch(props) {

  const { global } = useContext(store);

  const {
    isOpen,
    toogleModal,
    lang
  } = props;

  const {
    searchLabel,
    placeholder,
    search1,
    search2,
    noResultsSearch
  } = global;

  const [animation, setAnimation] = useState(false);

  const afterOpenModal = () => {
    setAnimation(true);
  };

  const afterCloseModal = () => {
    setAnimation(false);
  };

  const onRequestClose = () => {
    setAnimation(false);
  };

  useEffect(() => {
    if (isOpen === false) {
      onRequestClose();
    }
  }, [isOpen])

  let refSubmit = useRef();

  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(false);
  const [searched, setSearched] = useState(false);
  const [news, setNews] = useState({ rows: null, pagination: { currentPage: 1, lastPage: 1 } });
  const [products, setProducts] = useState([]);

  const clean = () => {
    setSearched(false);
    setSearch('');
  }

  const [getSearch, { loading }] = useLazyQuery(
    gql`
      query ($search: String!, $perPage: Int, $currentPage: Int){
        news: frontendArticles(perPage: $perPage, currentPage: $currentPage, search: $search){
          rows {
              id
              slug
              title
              excerpt
              date
          }
          pagination {
            currentPage
            lastPage
            total
          }
        }
        products: frontendProducts(search: $search, first: 1000, subproducts: true){
          edges{
            node{
              id
              slug
              name
              description
              areas {
                id
              }
              parent {
                slug
              }
            }
          }
          totalCount
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      onError: (error) => { console.log(error) },
      onCompleted: (data) => {
        if (data && data.news) {
          if (data.news.pagination.currentPage > 1) {
            setNews({ rows: [...news.rows, ...data.news.rows], pagination: data.news.pagination });
          } else {
            setNews(data.news);
          }
        }
        if (data?.products) {
          if (data?.products?.edges && data?.products?.edges?.length > 0) {
            const newArray = data.products.edges.map(x => ({
              cursor: x.cursor,
              content: x.node,
            })
            );
            setProducts(newArray);
          } else {
            setProducts([]);
          }
        }

        let tNews = data?.news?.pagination.total;
        let tProducts = data?.products?.totalCount ? data?.products?.totalCount : 0;
        setTotal(tNews + tProducts);
      }
    }
  );

  const searchSite = (event) => {
    event.preventDefault();
    requestData();
    setSearched(true);
  }

  const requestData = (params = {}) => {
    const { perPage = 4, currentPage = 1 } = params;

    getSearch({
      variables: {
        perPage: perPage,
        currentPage: currentPage,
        search: search && search.length > 3 ? search : undefined,
      }
    });
  }

  const renderTitle = (title) => {

    let temp = title.split('Sigma');
    let tempString = `Sigma<em>${temp[1] ? temp[1] : ''}</em>`;

    return (
      <TitleItem color="#35383B" dangerouslySetInnerHTML={{ __html: temp.length > 1 ? tempString : title }} />
    )
  }

  return (
    <Modal isOpen={isOpen} afterOpenModal={afterOpenModal} afterCloseModal={afterCloseModal}>
      <Overlay animation={animation} />
      <Content animation={animation}>
        <Structure>
          <Logo src='/static/images/logo_medidata.svg' />
          <ButtonModal onClick={() => toogleModal()}>
            <Icon src="/static/images/icon_close.svg" />
          </ButtonModal>
          <Section backgroundColor='#5BB990'>
            <Container lg>
              <Wrapper bothInitial noHeight>
                <DivSearch>
                  <Label color="#368D67" dangerouslySetInnerHTML={{ __html: searchLabel }} />
                  <Form onSubmit={(e) => searchSite(e)}>
                    <Input type="text" placeholder={placeholder} value={search} onChange={(e) => setSearch(e.target.value)} required />
                    <button type="submit" ref={refSubmit} style={{ display: 'none' }} />
                    {!searched &&
                      <ButtonSearch onClick={() => refSubmit.current.click()}>
                        <img src="/static/images/icon_search_green.svg" />
                      </ButtonSearch>
                    }
                    {searched &&
                      <ButtonSearch onClick={() => clean()}>
                        <img src="/static/images/icon_close.svg" />
                      </ButtonSearch>
                    }
                  </Form>
                  {searched &&
                    <ResultText>
                      {((!!news.rows && news.rows.length > 0) || products.length > 0) &&
                        <>
                          {search1} ({total}) {search2} <em>"{search}"</em>
                        </>
                      }
                      {((!!news.rows && news.rows.length === 0) && products.length === 0) &&
                        <>
                          {noResultsSearch}
                        </>
                      }
                    </ResultText>
                  }
                </DivSearch>
              </Wrapper>
            </Container>
          </Section>
          <Section backgroundColor={(searched === true && ((!!news.rows && news.rows.length > 0) || products.length > 0)) ? '#fff' : '#5BB990'} style={{ flex: 1, transition: 'all 0.4s ease-in-out' }}>
            <Container lg>
              <Wrapper bothInitial noHeight>
                {(searched === true && ((!!news.rows && news.rows.length > 0) || products.length > 0)) &&
                  <DivColumn>
                    <DivRow>
                      <Text16 color='#35383B' font='InterBold' style={{ marginRight: 20, width: 176 }} dangerouslySetInnerHTML={{ __html: `Notícias (${news.pagination.total})` }} />
                      <DivColumn style={{ flex: 1 }}>
                        <ContainerNews>
                          {(news.rows && news.rows.length > 0) && news.rows.map((item, index) => (
                            <Item key={String(index)}>
                              <Text12 color="#B2B6B9" dangerouslySetInnerHTML={{ __html: moment(item?.date).format('DD.MM.YYYY') }} />
                              <Margin />
                              <H5 color="#35383B" style={{ fontSize: 24 }} height={29} maxWidth="242px" dangerouslySetInnerHTML={{ __html: (item?.title?.length > 48) ? item?.title?.slice(0, 48) + "..." : item?.title }} />
                              <Margin margin={2} />
                              <Text14 color="#B2B6B9" style={{ minHeight: 72, maxHeight: 72 }} height={24} maxWidth="242px" dangerouslySetInnerHTML={{ __html: (item?.excerpt?.length > 84) ? item?.excerpt?.slice(0, 84) + "..." : item?.excerpt }} />
                              <Margin margin={4} />
                              <ButtonNews to={'noticias' + item?.slug}>
                                <Text14 color="#368D67" maxWidth="258px" dangerouslySetInnerHTML={{ __html: 'Ler mais' }} />
                                <IconSearch src="/static/images/arrow_right.svg" />
                              </ButtonNews>
                            </Item>
                          ))}
                        </ContainerNews>
                        {news.pagination.currentPage < news.pagination.lastPage &&
                          <DivButtonLoadMore>
                            <Button text="Ver mais" onClick={() => requestData({ currentPage: news.pagination.currentPage + 1 })} loading={loading} />
                          </DivButtonLoadMore>
                        }
                      </DivColumn>
                    </DivRow>
                    <DivRow>
                      <Text16 color='#35383B' font='InterBold' style={{ marginRight: 20, width: 176 }} dangerouslySetInnerHTML={{ __html: `Produtos (${products.length})` }} />
                      <ContainerProducts>
                        {(products && products?.length > 0) && products?.map((product, index) => (
                          <ItemProduct key={String(index)}>
                            {renderTitle(product.content?.name)}
                            <Margin />
                            <Text16 color="#B2B6B9" dangerouslySetInnerHTML={{ __html: (product?.content?.description && product?.content?.description.length > 116) ? product?.content?.description.slice(0, 116) + "..." : product?.content?.description }} />
                            {product?.content?.parent &&
                              <ButtonProduct to={'/' + lang + '/produtos' + product?.content?.parent?.slug + '/aplicacao' + product?.content?.slug}>
                                <IconPlus src="/static/images/icon_plus.svg" />
                              </ButtonProduct>
                            }
                            {!product?.content?.parent &&
                              <ButtonProduct to={'produtos' + product?.content?.slug}>
                                <IconPlus src="/static/images/icon_plus.svg" />
                              </ButtonProduct>
                            }
                          </ItemProduct>
                        ))}
                      </ContainerProducts>
                    </DivRow>
                  </DivColumn>
                }
              </Wrapper>
            </Container>
          </Section>
        </Structure>
      </Content>
    </Modal>
  )
}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0);
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  ${({ animation }) => animation && css`
    background-color: rgba(0,0,0,0);
  `}
`;
const Structure = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow:column;
  box-sizing: border-box;
  background: #fff;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateX(-100%);
`;
const Content = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  min-height: 100%;
  display: flex;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  ${({ animation }) => animation && css`
    opacity: 1;
    ${Structure} {
      transform: translateX(0%);
    }
  `};
  @media screen and (max-width: 1200px){
    width:100%;
  }
`;
const Logo = styled.img`
  position: absolute;
  top:24px;
  left:24px;
  max-height: 32px;
  object-fit: contain;
`;
const ButtonModal = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease-in-out;
  z-index: 1;
  :hover{
    cursor: pointer;
  }
`;
const Icon = styled.img`
  height: 20px;
  width: 20px;
  max-height: 20px;
  max-width: 20px;
  object-fit: contain;
`;
const DivSearch = styled.div`
  display:flex;
  flex-flow: column;
`;
const Label = styled(Text12)`
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 12px;
  font-family: 'InterBlack', sans-serif; 
`;
const ResultText = styled.div`
  margin-top: 20px;
  font-size: 24px;
  line-height: 32px;
  font-family: 'InterRegular', sans-serif; 
  color: #fff;
  em{
    font-style: normal;
    color: #236146
  }
`;
const Form = styled.form`
  position: relative;
  width: 100%;
  height: 100%;
`;
const Input = styled.input`
  padding: 4px 0;
  padding-right: 52px;
  width: calc(100% - 52px);
  border: unset;
  border-radius: 0;
  border-bottom: 1px solid #368D67;
  background: transparent;
  font-size: calc(32px + (56 - 32) * ((100vw - 375px) / (1920 - 375)));
  color: #236146;
  font-family: 'InterRegular', sans-serif; 
  ::placeholder{
    color: #236146;
  }
`;
const ButtonSearch = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    max-height: 32px;
    max-width: 32px;
    object-fit: contain;
  }
  :hover{
    cursor: pointer;
  }
`;
const DivRow = styled.div`
  display:flex;
  flex-flow: row;
  margin-bottom: 120px;
  :last-child{
    margin-bottom:0;
  }
  @media screen and (max-width: 900px){
    flex-flow: column;
  }
`;
const DivColumn = styled.div`
  display:flex;
  flex-flow: column;
`;
const ContainerNews = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  /* justify-content: center; */
  margin-bottom: -60px;
  flex: 1;
  @media screen and (max-width: 670px){
    margin-top: 20px;
  }
`;
const Item = styled.div`
  width: 25%;
  height: 260px;
  display: flex;
  flex-flow: column;
  margin-bottom: 60px;
  box-sizing: border-box;
  @media screen and (max-width: 1200px){
    width: 50%;
  }
  @media screen and (max-width: 670px){
    width: 100%;
  }
`;
const IconSearch = styled.img`
  width: 14px;
  height: 12px;
  object-fit: contain;
  margin-left: 8px;
  transition: all 0.4s ease-in-out;
`;
const ButtonNews = styled(Link)`
  display: flex;
  flex-flow: row;
  align-items: center;
  :hover{
    cursor: pointer;
    ${Icon}{
      margin-left: 12px;
    }
  }
`;
const DivButtonLoadMore = styled.div`
  margin-top: 80px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  width: 100%;
`;

const ContainerProducts = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin: -10px;
  flex: 1;
  @media screen and (max-width: 670px){
    margin-top: 20px;
  }
`;
const ItemProduct = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  width: calc(33% - 20px);
  max-width: 372px;
  height: 248px;
  padding: 32px 24px;
  box-sizing: border-box;
  margin: 10px;
  display: flex;
  flex-flow: column;
  position: relative;
  @media screen and (max-width: 1400px){
    width: calc(50% - 20px);
  }
  @media screen and (max-width: 670px){
    margin-top: 10px !important;
    width: calc(100% - 20px);
  }
`;
const TitleItem = styled(H6)`
  line-height: 29px;
  em{
    font-style: normal;
    color: #368D67;
  }
`;
const IconPlus = styled(ReactSVG)`
  width: 16px;
  height: 16px;
  object-fit: contain;
  svg{
    path{
      transition: all 0.5s ease-in-out;
      stroke: #D8DADC;
    }
  }
`;
const ButtonProduct = styled(Link)`
  position: absolute;
  right: 16px;
  bottom: 16px;
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid #D8DADC;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;
  :hover{
    cursor: pointer;
    border-color: #5D6368;
    ${IconPlus}{
      svg{
        path{
          stroke: #5D6368;
        }
      }
    }
  }
`;
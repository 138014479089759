import React, { useContext, useState } from 'react';
import CountUp from 'react-countup';
import { Waypoint } from 'react-waypoint';
import styled from 'styled-components';
import { store } from '../../../utils/store';
import { Button, Container, H1, H2, Section, Text18, Wrapper } from '../../atoms';
import { Intro } from '../../molecules';

export default function MedidataMundo({ buttonText, buttonLink, description, smallTitle, textHand, title, language }) {

  const [alreadyClick, setAlreadyClick] = useState(false);
  const { medidataMundo } = useContext(store);

  return (
    <Section backgroundColor="#5BB990" noFade>
      <Wrapper both noHeight >
        <Container md>
          <DivRowSpace>
            <DivContainer>
              <Intro
                smallTitleColor="#368D67"
                smallTitle={smallTitle}
                titleSize="H3"
                title={title}
                titleColor="#35383B"
              />
              <TitleKnowHow color="#368D67" style={{ fontSize: 56 }} dangerouslySetInnerHTML={{ __html: textHand }} />
              <Margin margin={2.5} />
              <Text18 color="#fff" maxWidth="470px" dangerouslySetInnerHTML={{ __html: description }} />
              <Margin margin={8} />
            </DivContainer>
            <Button link={"/" + language + buttonLink} text={buttonText} color="236146" />
          </DivRowSpace>
          <Waypoint
            onEnter={function () {
              if (alreadyClick === false) {
                document.getElementById('anos').click();
                document.getElementById('clientes').click();
                document.getElementById('utilizadores').click();
                document.getElementById('paises').click();
                document.getElementById('autarquias').click();
                document.getElementById('produtos').click();
                setAlreadyClick(true);
              }
            }}
          >
            <ContainerNumbers>
              <BoxNumber>
                <CountUp start={0} end={parseInt(medidataMundo?.numeroAnos)} delay={0.4} duration={10}>
                  {({ countUpRef, start }) => (
                    <div>
                      <H1 color="#236146" font="InterRegular" ref={countUpRef} />
                      <button id="anos" style={{ display: 'none' }} onClick={start}></button>
                    </div>
                  )}
                </CountUp>
                <Margin margin={1.5} />
                <Text18 color="#368D67" font="InterRegular" dangerouslySetInnerHTML={{ __html: medidataMundo?.textoAnos }} />
              </BoxNumber>
              <BoxNumber>
                <CountUp start={0} end={parseInt(medidataMundo?.numeroClientes)} delay={0.4} duration={10}>
                  {({ countUpRef, start }) => (
                    <div style={{ display: 'inline-flex' }}>
                      <H1 color="#236146" font="InterRegular" ref={countUpRef} />
                      <H1 color="#236146" font="InterRegular" dangerouslySetInnerHTML={{ __html: '+' }} />
                      <button id="clientes" style={{ display: 'none' }} onClick={start}></button>
                    </div>
                  )}
                </CountUp>
                <Margin margin={1.5} />
                <Text18 color="#368D67" font="InterRegular" dangerouslySetInnerHTML={{ __html: medidataMundo?.textoClientes }} />
              </BoxNumber>
              <BoxNumber>
                <CountUp start={0} end={parseInt(medidataMundo?.numeroUtilizadores)} delay={0.4} duration={10}>
                  {({ countUpRef, start }) => (
                    <div style={{ display: 'inline-flex' }}>
                      <H1 color="#236146" font="InterRegular" ref={countUpRef} />
                      <H1 color="#236146" font="InterRegular" dangerouslySetInnerHTML={{ __html: '+' }} />
                      <button id="utilizadores" style={{ display: 'none' }} onClick={start}></button>
                    </div>
                  )}
                </CountUp>
                <Margin margin={1.5} />
                <Text18 color="#368D67" font="InterRegular" dangerouslySetInnerHTML={{ __html: medidataMundo?.textoUtilizadores }} />
              </BoxNumber>
              <BoxNumber>
                <CountUp start={0} end={parseInt(medidataMundo?.numeroPaises)} delay={0.4} duration={10}>
                  {({ countUpRef, start }) => (
                    <div>
                      <H1 color="#236146" font="InterRegular" ref={countUpRef} />
                      <button id="paises" style={{ display: 'none' }} onClick={start}></button>
                    </div>
                  )}
                </CountUp>
                <Margin margin={1.5} />
                <Text18 color="#368D67" font="InterRegular" dangerouslySetInnerHTML={{ __html: medidataMundo?.textoPaises }} />
              </BoxNumber>
              <BoxNumber>
                <CountUp start={0} end={parseInt(medidataMundo?.numeroAutarquias)} delay={0.4} duration={10}>
                  {({ countUpRef, start }) => (
                    <div style={{ display: 'inline-flex' }}>
                      <H1 color="#236146" font="InterRegular" ref={countUpRef} />
                      <H1 color="#236146" font="InterRegular" dangerouslySetInnerHTML={{ __html: '+' }} />
                      <button id="autarquias" style={{ display: 'none' }} onClick={start}></button>
                    </div>
                  )}
                </CountUp>
                <Margin margin={1.5} />
                <Text18 color="#368D67" font="InterRegular" dangerouslySetInnerHTML={{ __html: medidataMundo?.textoAutarquias }} />
              </BoxNumber>
              <BoxNumber>
                <CountUp start={0} end={parseInt(medidataMundo?.numeroProdutos)} delay={0.4} duration={10}>
                  {({ countUpRef, start }) => (
                    <div style={{ display: 'inline-flex' }}>
                      <H1 color="#236146" font="InterRegular" ref={countUpRef} />
                      <H1 color="#236146" font="InterRegular" dangerouslySetInnerHTML={{ __html: '+' }} />
                      <button id="produtos" style={{ display: 'none' }} onClick={start}></button>
                    </div>
                  )}
                </CountUp>
                <Margin margin={1.5} />
                <Text18 color="#368D67" font="InterRegular" dangerouslySetInnerHTML={{ __html: medidataMundo?.textoProdutos }} />
              </BoxNumber>
            </ContainerNumbers>
          </Waypoint>
        </Container>
      </Wrapper>
    </Section>
  )
}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const DivContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`;
const TitleKnowHow = styled(H2)`
  font-family: 'Rockness', sans-serif;
`;
const DivRowSpace = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  @media screen and (max-width: 760px){
    flex-flow: column;
  }
`;
const ContainerNumbers = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -52px;
  @media screen and (max-width: 760px){
    margin-top: 52px;
  }
`;
const BoxNumber = styled.div`
  width: 33%;
  display: flex;
  flex-flow: column;
  margin-bottom: 52px;
  @media screen and (max-width: 760px){
    width: 50%;
  }
`;
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import moment from 'moment';
import 'moment/locale/pt';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import { store } from '../../../utils/store';
import { Button, Checkbox, Container, H4, H5, Section, Text10, Text12, Text14, Text16, Text18, Wrapper } from '../../atoms';
import { Loading, ModalCourse } from '../../molecules';

export default function TextServicoSection({ arr, courses, keyCourses, titleCourses, labelDate, labelHour, labelLocal, labelPeople, buttonTextCourse, language, ...props }) {

  const { global } = useContext(store);

  const {
    buttonText,
    descriptionModal,
    emailLabel,
    emailPlaceholder,
    labelContact,
    labelEntity,
    labelNIF,
    labelOnlineCourse,
    labelPreRequirements,
    labelNorms,
    labelButtonContents,
    labelButtonInquiry,
    labelModalInquiryTitle,
    labelModalInquiryDescription,
    modalSignupThanksText,
    modalInquiryThanksText,
    modalInquiryLabelFieldTrainer,
    modalInquiryPlaceholderFieldTrainer,
    modalInquiryLabelFieldSugestions,
    modalInquiryQuestions,
    modalInquiryOptions,
    logoModal,
    nameLabel,
    namePlaceholder,
    placehodlerContanct,
    placeholderEntity,
    placeholderNIF,
    smallTitleModal,
    textRequired,
    textTerms,
    textTerms2,
    titleDados
  } = props;

  const [active, setActive] = useState(-1);
  const [array, setArray] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModal2, setIsOpenModal2] = useState(false);
  const [modalContent, setModalContent] = useState(undefined);

  useEffect(() => {
    if ((courses && courses.length > 0) && keyCourses && titleCourses) {
      let obj = {
        key: keyCourses,
        title: titleCourses,
        formation: {
          cursos: courses
        }
      };
      arr.push(obj);
      setArray(arr);
    } else {
      setArray(arr);
    }
  }, [arr])

  const calcDatesCourse = (dates, hours) => {
    let first = dates[0].date;
    let last = dates[dates.length - 1].date;
    let days;
    if (dates.length === 1) {
      days = `${moment(new Date(`${first} ${(!!hours && !!hours[0]) ? hours[0].hourStart : '00:00'}`)).format('DD MMMM YYYY')}`;
    } else {
      days = `${moment(new Date(`${first} ${(!!hours && !!hours[0]) ? hours[0].hourStart : '00:00'}`)).format('DD MMMM YYYY')} - ${moment(new Date(`${last} ${(!!hours && !!hours[hours.length - 1]) ? hours[hours.length - 1].hourEnd : '00:00'}`)).format('DD MMMM YYYY')}`;
    }
    return (
      <Text16 color="#5D6368" dangerouslySetInnerHTML={{ __html: days }} />
    )
  }

  const [listDates, setListDates] = useState([]);
  const [date, setDate] = useState(undefined);
  const [listLocals, setListLocals] = useState([]);
  const [checkedMark, setCheckedMark] = useState(false);
  const [missCheck, setMissCheck] = useState(false);
  const [checkedMark2, setCheckedMark2] = useState(false);
  const [missCheck2, setMissCheck2] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const toogleModalFor = (course) => {
    setIsOpenModal(!isOpenModal);
    setModalContent({
      name: course.name,
      dates: course.listDates
    })
  }

  const toogleModalFor2 = (course) => {
    setIsOpenModal2(!isOpenModal2);
    setModalContent({
      name: course.name,
      dates: course.listDates
    })
  }

  useEffect(() => {
    setInputs({});
    setSubmited(false);
    setCheckedMark(false);
    setCheckedMark2(false);
  }, [isOpenModal, isOpenModal2]);

  useEffect(() => {
    if (modalContent && modalContent?.dates && modalContent?.dates?.length > 0) {
      let temp = [];
      for (let i = 0; i < modalContent.dates.length; i++) {
        temp.push(
          {
            value: modalContent.dates[i].date,
            label: modalContent.dates[i].date
          }
        )
      }
      setListDates(temp);
    }
  }, [modalContent])

  useEffect(() => {
    const result = modalContent?.dates.filter((x) => x.date === date);
    if (result && result.length > 0) {
      let temp = [];
      for (let i = 0; i < result.length; i++) {
        temp.push(
          {
            value: result[i].local,
            label: result[i].local
          }
        )
      }
      setListLocals(temp);
    }
  }, [date]);

  const [inputs, setInputs] = useState({});

  const handleInput = (event) => {
    event.persist();
    setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }))
  }

  const [sendForm] = useMutation(
    gql`
      mutation ($course: String!, $dates: String!, $name: String!, $email: String!, $contact: String!, $entity: String!, $nif: String!){
        customFormationSendEmail(course: $course, dates: $dates, name: $name, email: $email, contact: $contact, entity: $entity, nif: $nif)
      }
    `,
    {
      onError: () => {
        setLoadingForm(false)
        setSuccess(false);
        setError(true);
      },
      onCompleted: (data) => {
        setLoadingForm(false);
        if (data.customFormationSendEmail === true) {
          setSuccess(true);
          setError(false);
        } else {
          setSuccess(false);
          setError(true);
        }
      }
    }
  );

  const [sendInquiry] = useMutation(
    gql`
      mutation ($name: String!, $entity: String!, $course: String!, $trainer: String!, $suggestions: String, $qa: [InputCustomInquiryQA!]!){
        customSendInquirySendEmail(name: $name, entity: $entity, course:$course, trainer: $trainer, suggestions: $suggestions, qa: $qa)
      }
    `,
    {
      onError: () => {
        setLoadingForm(false)
        setSuccess(false);
        setError(true);
      },
      onCompleted: (data) => {
        setLoadingForm(false);
        if (data.customSendInquirySendEmail === true) {
          setSuccess(true);
          setError(false);
        } else {
          setSuccess(false);
          setError(true);
        }
      }
    }
  );

  const submitForm = (e) => {
    e.preventDefault();
    if (checkedMark === true && checkedMark2 === true) {
      setMissCheck(false);
      setMissCheck2(false);
      setLoadingForm(true);
      setSubmited(true);
      sendForm({
        variables: {
          course: modalContent.name,
          dates: modalContent.dates && modalContent.dates.map((item => moment(new Date(`${item.date} ${(!!modalContent.hours && !!modalContent.hours[0]) ? modalContent.hours[0].hourStart : '00:00'}`)).format('DD-MM-YYYY'))).join(", "),
          name: inputs.name,
          email: inputs.email,
          contact: inputs.contact,
          entity: inputs.entity,
          nif: inputs.nif,
        }
      })

    } else {
      if (checkedMark === false) {
        setMissCheck(true);
      }
      if (checkedMark2 === false) {
        setMissCheck2(true);
      }
    }
  }

  const submitForm2 = (e) => {
    e.preventDefault();
    if (checkedMark === true && checkedMark2 === true) {
      setMissCheck(false);
      setMissCheck2(false);
      setLoadingForm(true);
      setSubmited(true);
      sendInquiry({
        variables: {
          name: inputs.name,
          entity: inputs.entity,
          course: modalContent.name,
          trainer: inputs.trainer,
          suggestions: inputs.suggestions,
          qa: [
            { question: modalInquiryQuestions[0].question, option: inputs['qa[0]'] },
            { question: modalInquiryQuestions[1].question, option: inputs['qa[1]'] },
            { question: modalInquiryQuestions[2].question, option: inputs['qa[2]'] },
            { question: modalInquiryQuestions[3].question, option: inputs['qa[3]'] },
            { question: modalInquiryQuestions[4].question, option: inputs['qa[4]'] },
            { question: "Tipo de curso preferencial", option: inputs['qa[5]'] }
          ]
        }
      })

    } else {
      if (checkedMark === false) {
        setMissCheck(true);
      }
      if (checkedMark2 === false) {
        setMissCheck2(true);
      }
    }
  }

  const coursesList = (content) => {
    return (
      <DivColumnCourses>
        <H5 color="#35383B" dangerouslySetInnerHTML={{ __html: content.title }} />
        {(content.cursos && content.cursos.length > 0) &&
          <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
            <DivColumnCourse>
              {
                content.cursos.map((curso, index) => (
                  <ItemCourse key={String(index)}>
                    <DivIntroCourse>
                      <Text18 color="#35383B" style={{ width: 372 }} dangerouslySetInnerHTML={{ __html: curso.name }} />
                      {(curso.listDates && curso.listDates.length > 0) &&
                        <>
                          {calcDatesCourse(curso.listDates, curso.hours)}
                        </>
                      }
                      <div style={{ display: "flex", alignItems: "center", gap: "24px" }}>
                        <Button text={global?.buttonTextCourse} color="368D67" onClick={() => toogleModalFor(curso)} />
                        <ButtonOpen onClick={() => (active === index) ? setActive(-1) : setActive(index)}>
                          <img src={active === index ? '/static/images/minus.svg' : '/static/images/plus.svg'} />
                        </ButtonOpen>
                      </div>
                    </DivIntroCourse>
                    <DivContainerCourse className={active === index ? 'active' : null} style={{ zIndex: active ? 1 : 0 }}>
                      <Table>
                        <thead>
                          <tr>
                            <th>{global?.labelDate}</th>
                            <th>{global?.labelHour}</th>
                            <th>{global?.labelLocal}</th>
                            <th>{global?.labelPeople}</th>
                            <th>{global?.labelValuePerPerson}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td className="line">
                              {curso.listDates.map((item, l) => (
                                <Text16 key={String(l)} color="#5D6368" style={{ textTransform: 'capitalize' }} dangerouslySetInnerHTML={{ __html: moment(new Date(`${item.date} ${(!!curso.hours && !!curso.hours[0]) ? curso.hours[0].hourStart : '00:00'}`)).format('DD MMMM YYYY') }} />
                              ))}
                            </td>
                            <td className="line column">
                              <ContainerHours>
                                {curso.hours.map((hours, h) => (
                                  <DivHours key={String(h)}>
                                    <Text16 color="#5D6368" style={{ textTransform: 'capitalize' }} dangerouslySetInnerHTML={{ __html: hours.hourStart }} />
                                    <Text16 color="#5D6368" style={{ textTransform: 'capitalize', marginLeft: 4, marginRight: 4 }} dangerouslySetInnerHTML={{ __html: '-' }} />
                                    <Text16 color="#5D6368" style={{ textTransform: 'capitalize' }} dangerouslySetInnerHTML={{ __html: hours.hourEnd }} />
                                  </DivHours>
                                ))}
                              </ContainerHours>
                            </td>
                            <td className="line">
                              <Text16 color="#5D6368" style={{ textTransform: 'capitalize' }} dangerouslySetInnerHTML={{ __html: curso.local }} />
                              <Text16 color="#5D6368" style={{ textTransform: 'capitalize' }} dangerouslySetInnerHTML={{ __html: curso.palce }} />
                            </td>
                            <td className="line">
                              <Text16 color="#5D6368" style={{ textTransform: 'capitalize' }} dangerouslySetInnerHTML={{ __html: 'Máx ' + curso.people }} />
                            </td>
                            <td className="line">
                              <Text16 color="#5D6368" style={{ textTransform: 'capitalize' }} dangerouslySetInnerHTML={{ __html: curso.price + '*' }} />
                              <Text12 color="#5D6368" dangerouslySetInnerHTML={{ __html: global?.labelIVAInfo }} />
                            </td>
                          </tr>
                        </tbody>

                      </Table>
                      <Margin margin={4} />
                      <DivColumn>
                        <DivRow>
                          {!!curso.buttonContentsFileLink && <div style={{ marginRight: "24px" }}>
                            <Button text={labelButtonContents} color="368D67" onClick={() => window.open(curso.buttonContentsFileLink.url, "_blank")} />
                          </div>}
                          <Button text={labelButtonInquiry} color="368D67" onClick={() => toogleModalFor2(curso)} />
                        </DivRow>
                        <Margin margin={3} />
                        <Text12 font="InterBlack" color="#35383B" style={{ letterSpacing: '0.1em', textTransform: 'uppercase' }} dangerouslySetInnerHTML={{ __html: labelOnlineCourse }} />
                        <DivRow>
                          <Text16 color="#5D6368" dangerouslySetInnerHTML={{ __html: curso.textOnlineCourse }} />
                        </DivRow>
                        <Margin margin={3} />
                        <Text12 font="InterBlack" color="#35383B" style={{ letterSpacing: '0.1em', textTransform: 'uppercase' }} dangerouslySetInnerHTML={{ __html: labelPreRequirements }} />
                        <DivRow>
                          <Text16 color="#5D6368" dangerouslySetInnerHTML={{ __html: curso.textPreRequirements }} />
                        </DivRow>
                        <Margin margin={3} />
                        <Text12 font="InterBlack" color="#35383B" style={{ letterSpacing: '0.1em', textTransform: 'uppercase' }} dangerouslySetInnerHTML={{ __html: labelNorms }} />
                        <DivRow>
                          <Text16 color="#5D6368" dangerouslySetInnerHTML={{ __html: curso.textNorms }} />
                        </DivRow>
                      </DivColumn>
                    </DivContainerCourse>
                  </ItemCourse>
                ))}
            </DivColumnCourse>
          </div>
        }
      </DivColumnCourses>
    )
  }

  return (
    <>
      <Section backgroundColor="#fff">
        <Container md id="item" >
          <Wrapper both noHeight>
            <DivContainer>
              <DivSection>
                <DivColumnTitle>
                  {(array && array.length > 0) && array.map((innerText, index) => (
                    <TitleSection
                      key={String(index)}
                      font="InterRegular"
                      activeClass='active'
                      to={innerText.key}
                      spy={true}
                      smooth={true}
                      duration={500}
                      delay={50}
                      offset={-140}
                    >
                      <Text16 dangerouslySetInnerHTML={{ __html: innerText.title }} />
                    </TitleSection>
                  ))}
                </DivColumnTitle>
              </DivSection>
              <DivColumn style={{ maxWidth: '100%' }}>
                {(array && array.length > 0) && array.map((innerText, index) => (
                  <DivItem key={String(index)} className={innerText.key && innerText.key}>
                    {innerText.title &&
                      <TitleText color="#35383B" font="InterBold" dangerouslySetInnerHTML={{ __html: innerText.title }} />
                    }
                    {innerText.text &&
                      <Text
                        key={String(index)}
                        font="InterRegular"
                        color="#5D6368"
                        maxWidth="960px"
                        dangerouslySetInnerHTML={{ __html: innerText.text }}
                      />
                    }
                    {(innerText.buttonLink && innerText.buttonText) &&
                      <Margin margin={4}>
                        <Button text={innerText.buttonText} link={'/' + language + innerText.buttonLink} color="368D67" />
                      </Margin>
                    }
                    {(innerText.formation && innerText.formation !== null && innerText.formation.cursos && innerText.formation.cursos.length > 0) &&
                      <>
                        {coursesList(innerText.formation)}
                      </>
                    }
                  </DivItem>
                ))}
              </DivColumn>
            </DivContainer>
          </Wrapper>
        </Container>
      </Section>
      <ModalCourse isOpen={isOpenModal}>
        <ButtonClose onClick={() => setIsOpenModal(!isOpenModal)}>
          <img src='/static/images/icon_close.svg' />
        </ButtonClose>
        <LogoModal submited={submited} src={logoModal?.url} />
        {(!submited && !loadingForm) &&
          <>
            <Text12 color="#368D67" font='InterBold' style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} dangerouslySetInnerHTML={{ __html: smallTitleModal }} />
            <Margin margin={1.5} />
            <H4 color='#35383B' dangerouslySetInnerHTML={{ __html: modalContent?.name }} />
            <Margin margin={2.5} />
            <Text18 color="#B2B6B9" dangerouslySetInnerHTML={{ __html: descriptionModal }} />
            <Margin margin={4.5} />
            <Form onSubmit={(e) => submitForm(e)}>
              <Text10 color="#5D6368" font='InterBold' style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} dangerouslySetInnerHTML={{ __html: nameLabel }} />
              <Margin />
              <Input type='text' name='name' onChange={handleInput} placeholder={namePlaceholder} required />
              <Margin margin={2} />
              <Text10 color="#5D6368" font='InterBold' style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} dangerouslySetInnerHTML={{ __html: emailLabel }} />
              <Margin />
              <Input type='email' name='email' onChange={handleInput} placeholder={emailPlaceholder} required />
              <Margin margin={2} />
              <Text10 color="#5D6368" font='InterBold' style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} dangerouslySetInnerHTML={{ __html: labelContact }} />
              <Margin />
              <Input type='text' name='contact' onChange={handleInput} placeholder={placehodlerContanct} required />
              <Margin margin={2} />
              <Text10 color="#5D6368" font='InterBold' style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} dangerouslySetInnerHTML={{ __html: labelEntity }} />
              <Margin />
              <Input type='text' name='entity' onChange={handleInput} placeholder={placeholderEntity} required />
              <Margin margin={2} />
              <Text10 color="#5D6368" font='InterBold' style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} dangerouslySetInnerHTML={{ __html: labelNIF }} />
              <Margin />
              <Input type='text' name='nif' onChange={handleInput} placeholder={placeholderNIF} required />
              <Margin margin={1.5} />
              <Text12 color="#B2B6B9" dangerouslySetInnerHTML={{ __html: textRequired }} />
              <Margin margin={3} />
              <DivRow style={{ alignItems: 'center' }}>
                <label>
                  <Checkbox
                    error={missCheck}
                    checked={checkedMark}
                    onClick={() => setCheckedMark(!checkedMark)}
                  />
                </label>
                <TextTerms style={{ marginLeft: 4 }} color="#35383B" dangerouslySetInnerHTML={{ __html: textTerms }} />
              </DivRow>
              <DivRow style={{ alignItems: 'center' }}>
                <label>
                  <Checkbox
                    error={missCheck2}
                    checked={checkedMark2}
                    onClick={() => setCheckedMark2(!checkedMark2)}
                  />
                </label>
                <TextTerms style={{ marginLeft: 4 }} color="#35383B" dangerouslySetInnerHTML={{ __html: textTerms2 }} />
              </DivRow>
              <Margin margin={4.5} />
              <ButtonSubmit color='368D67' type='submit'>
                <svg>
                  <rect x="0" y="0" fill="none" width="100%" height="100%" />
                </svg>
                <Text14 color="#368D67" dangerouslySetInnerHTML={{ __html: buttonText }} />
              </ButtonSubmit>
            </Form>
          </>
        }
        {(submited && !loadingForm) &&
          <ContainerSubmited>
            <ConfirmLogo src='/static/images/icon_confirmacao.svg' />
            <Margin margin={4} />
            <H4 color='#35383B' align='center' dangerouslySetInnerHTML={{ __html: modalContent?.name }} />
            <Margin margin={2} />
            <Text16 color="#B2B6B9" maxWidth='390px' align='center' dangerouslySetInnerHTML={{ __html: modalSignupThanksText }} />
            <Margin margin={7} />
            <Button onClick={() => setIsOpenModal(!isOpenModal)} text='Obrigado!' color='368D67' />
          </ContainerSubmited>
        }
        {(loadingForm) &&
          <ContainerSubmited>
            <Loading isLoading={loadingForm} />
          </ContainerSubmited>
        }
      </ModalCourse>
      <ModalCourse isOpen={isOpenModal2}>
        <ButtonClose onClick={() => setIsOpenModal2(!isOpenModal2)}>
          <img src='/static/images/icon_close.svg' />
        </ButtonClose>
        <LogoModal submited={submited} src={logoModal?.url} />
        {(!submited && !loadingForm) &&
          <>
            <Text12 color="#368D67" font='InterBold' style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} dangerouslySetInnerHTML={{ __html: labelModalInquiryTitle }} />
            <Margin margin={1.5} />
            <H4 color='#35383B' dangerouslySetInnerHTML={{ __html: modalContent?.name }} />
            <Margin margin={2.5} />
            <Text18 color="#B2B6B9" dangerouslySetInnerHTML={{ __html: labelModalInquiryDescription }} />
            <Margin margin={4.5} />
            <Form onSubmit={(e) => submitForm2(e)}>
              <Text10 color="#5D6368" font='InterBold' style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} dangerouslySetInnerHTML={{ __html: nameLabel }} />
              <Margin />
              <Input type='text' name='name' placeholder={namePlaceholder} onChange={handleInput} required />
              <Margin margin={2} />
              <Text10 color="#5D6368" font='InterBold' style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} dangerouslySetInnerHTML={{ __html: "Entidade*" }} />
              <Margin />
              <Input type='text' name='entity' placeholder={"Entidade"} onChange={handleInput} required />
              <Margin margin={2} />
              <Text10 color="#5D6368" font='InterBold' style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} dangerouslySetInnerHTML={{ __html: modalInquiryLabelFieldTrainer }} />
              <Margin />
              <Input type='text' name='trainer' placeholder={modalInquiryPlaceholderFieldTrainer} onChange={handleInput} required />
              {modalInquiryQuestions && modalInquiryQuestions.map(({ question }, questionIndex) => {
                return (
                  <>
                    <Margin margin={2} />
                    <Text10 color="#5D6368" font='InterBold' style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} dangerouslySetInnerHTML={{ __html: question }} />
                    {modalInquiryOptions && modalInquiryOptions.map(({ option }, optionIndex) => {
                      return (
                        <>
                          <Margin />
                          <DivRow>
                            <input id={`${questionIndex}.${optionIndex}`} type='radio' name={`qa[${questionIndex}]`} placeholder={modalInquiryPlaceholderFieldTrainer} onChange={handleInput} required style={{ margin: 0 }} value={option} />
                            <label for={`${questionIndex}.${optionIndex}`} style={{ marginLeft: "4px", fontFamily: "InterRegular", fontSize: 12, color: "#5D6368", letterSpacing: '0.1em' }} dangerouslySetInnerHTML={{ __html: option }} />
                          </DivRow>
                        </>
                      );
                    })}
                  </>
                );
              })}
              <Margin margin={2} />
              <Text10 color="#5D6368" font='InterBold' style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} dangerouslySetInnerHTML={{ __html: "Tipo de curso preferencial" }} />
              <Margin />
              <DivRow>
                <input id={`${modalInquiryQuestions.length}.0`} type='radio' name={`qa[${modalInquiryQuestions.length}]`} placeholder={modalInquiryPlaceholderFieldTrainer} onChange={handleInput} required style={{ margin: 0 }} value={"Remoto"} />
                <label for={`${modalInquiryQuestions.length}.0`} style={{ marginLeft: "4px", fontFamily: "InterRegular", fontSize: 12, color: "#5D6368", letterSpacing: '0.1em' }} dangerouslySetInnerHTML={{ __html: "Remoto" }} />
              </DivRow>
              <Margin />
              <DivRow>
                <input id={`${modalInquiryQuestions.length}.1`} type='radio' name={`qa[${modalInquiryQuestions.length}]`} placeholder={modalInquiryPlaceholderFieldTrainer} onChange={handleInput} required style={{ margin: 0 }} value={"Presencial"} />
                <label for={`${modalInquiryQuestions.length}.1`} style={{ marginLeft: "4px", fontFamily: "InterRegular", fontSize: 12, color: "#5D6368", letterSpacing: '0.1em' }} dangerouslySetInnerHTML={{ __html: "Presencial" }} />
              </DivRow>
              <Margin margin={2} />
              <Text10 color="#5D6368" font='InterBold' style={{ textTransform: 'uppercase', letterSpacing: '0.1em' }} dangerouslySetInnerHTML={{ __html: modalInquiryLabelFieldSugestions }} />
              <Margin />
              <Textarea name='suggestions' onChange={handleInput} />
              <Margin margin={3} />
              <DivRow style={{ alignItems: 'center' }}>
                <label>
                  <Checkbox
                    error={missCheck}
                    checked={checkedMark}
                    onClick={() => setCheckedMark(!checkedMark)}
                  />
                </label>
                <TextTerms style={{ marginLeft: 4 }} color="#35383B" dangerouslySetInnerHTML={{ __html: textTerms }} />
              </DivRow>
              <DivRow style={{ alignItems: 'center' }}>
                <label>
                  <Checkbox
                    error={missCheck2}
                    checked={checkedMark2}
                    onClick={() => setCheckedMark2(!checkedMark2)}
                  />
                </label>
                <TextTerms style={{ marginLeft: 4 }} color="#35383B" dangerouslySetInnerHTML={{ __html: textTerms2 }} />
              </DivRow>
              <Margin margin={4.5} />
              <ButtonSubmit color='368D67' type='submit'>
                <svg>
                  <rect x="0" y="0" fill="none" width="100%" height="100%" />
                </svg>
                <Text14 color="#368D67" dangerouslySetInnerHTML={{ __html: buttonText }} />
              </ButtonSubmit>
            </Form>
          </>
        }
        {(submited && !loadingForm) &&
          <ContainerSubmited>
            <ConfirmLogo src='/static/images/icon_confirmacao.svg' />
            <Margin margin={4} />
            <H4 color='#35383B' align='center' dangerouslySetInnerHTML={{ __html: modalContent?.name }} />
            <Margin margin={2} />
            <Text16 color="#B2B6B9" maxWidth='390px' align='center' dangerouslySetInnerHTML={{ __html: modalInquiryThanksText }} />
            <Margin margin={7} />
            <Button onClick={() => setIsOpenModal2(!isOpenModal2)} text='Obrigado!' color='368D67' />
          </ContainerSubmited>
        }
        {(loadingForm) &&
          <ContainerSubmited>
            <Loading isLoading={loadingForm} />
          </ContainerSubmited>
        }
      </ModalCourse>
    </>
  )
}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const DivSection = styled.div`
  display: block;
  @media screen and (max-width: 800px){
    display: none;
  }
`;
const DivContainer = styled.div`
  display: flex;
  flex-flow: row;
`;
const DivColumnTitle = styled.div`
  position: sticky;
  top: 120px;
  display: flex;
  flex-flow: column;
  width: 176px;
`;
const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
`;
const DivItem = styled.div`
  display: flex;
  flex-flow: column;
  overflow: auto;
  margin-bottom: calc(60px + (120 - 60) * ((100vw - 375px) / (1920 - 375)));
  :last-child{
    margin-bottom: 0;
  }
`;
const TitleSection = styled(Link)`
  margin-bottom: 8px;
  :last-child{
    margin-bottom: 0;
  }
  div {
    color: #B2B6B9;
    transition: all 0.4s ease-in-out;
  }
  :hover{
    cursor: pointer;
    div{
      color: #35383B;
    }
  }
  &.active{
    div {
      font-family: InterBold ,sans-serif;
      color: #35383B;
    }
  }
`;
const TitleText = styled(Text18)`
  @media screen and (min-width: 801px){
    display: none;
  }
  @media screen and (max-width: 800px){
    display: flex;
    margin-bottom: 16px;
  }
`;
const Text = styled(H4)`
  p{
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  p:first-child{
    margin-block-start: 0;
  }
  @media screen and (max-width: 768px){
    line-height: 32px;
  }
`;
const DivColumnCourses = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
`;
const DivColumnCourse = styled.div`
  display: flex;
  flex-flow: column;
  min-width: 800px;
  margin-top: 24px;
`;
const ItemCourse = styled.div`
  display: flex;
  flex-flow: column;
  border-top: 1px solid #D8DADC;
  :last-of-type{
    border-bottom: 1px solid #D8DADC;
  }
`;
const DivIntroCourse = styled.div`
  display:flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  z-index: 99;
`;
const ButtonOpen = styled.div`
  z-index: 1;
  width: 20px;
  height: 20px;
  :hover{
    cursor: pointer;
  }
  img{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;
const DivContainerCourse = styled.div`
  height: 0;
  opacity: 0;
  width: 100%;
  box-sizing: border-box;
  background-color: #F9FAFA;
  transition: all 0.4s ease-in-out;
  &.active{
    height: calc(100% - 120px);
    border-top: 1px solid #D8DADC;
    opacity: 1;
    box-sizing: border-box;
    padding-left: calc(25px + (100 - 25) * ((100vw - 375px) / (1920 - 375)));
    padding-top: 32px;
    padding-bottom: 88px;
  }
`;
const Table = styled.table`
  width: 100%;
  th {
    text-align: left;
    font-family: "InterBlack", sans-serif;
    font-size: 12px;
    line-height: 14px;
    color: #35383B;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding-bottom: 16px;
    border-bottom: 1px solid #D8DADC;
  }
  td{
    padding: 16px 0;
    border-bottom: 1px solid #D8DADC;
  }
`;
const ContainerHours = styled.div`
  display: flex;
  flex-flow: column;
`;
const DivHours = styled.div`
  display: inline-flex;
  margin-top: 8px;
  :first-child{
    margin: 0;
  }
`;
const DivRow = styled.div`
  display: flex;
  flex-flow: row;
`;
const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 100px;
  li { 
    display: flex;
    flex-flow: row; 
    margin-bottom: 16px;
  }
  li::last-child { 
    margin-bottom: 0;
  }
  li::before {
    content: "•";
    padding-top: 2px;
    padding-right: 8px;
    color: #B2B6B9;
  }
`;
const ButtonClose = styled.div`
  width: 24px;
  height: 20px;
  position:absolute;
  top: 16px;
  right: 32px;
  img{
    object-fit: contain
  }
  :hover{
    cursor: pointer;
  }
`;
const LogoModal = styled.img`
  max-height: 32px;
  object-fit: contain;
  margin: 0 auto;
  margin-bottom: 92px;

  ${({ submited }) => submited && css`
    position:absolute;
    top: 16px;
    left: 0;
    right: 0;
  `}
`;
const Form = styled.form`
  display: flex;
  flex-flow: column;
`;
const Dropdown = styled(Select)`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 17px;
  letter-spacing: normal;
  color: #35383B;
  border-radius: 0;
  width: 332px;
  height: 52px;
  max-width: 100%;
  .dropdown__control {
    padding: 0 16px;
    border: unset;
    box-shadow: 0 0 0 0px #000;
    background: #EBECED;
    border-radius: 2px;
    width: 332px;
    max-width: 100%;
    height: 52px;
    :hover {
      border-color: inherit !important;
    }
    .dropdown__value-container{
      padding: 0;
    }
  }
  .dropdown__indicator {
    padding: 0px;
    width: 15px;
    color: #B2B6B9;
  }
  .dropdown__indicator-separator {
    width: 0px;
  }
  .dropdown__placeholder,
  .dropdown__single-value {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 17px;
    letter-spacing: normal;
    color: #B2B6B9;
    border-radius: 0;
  }
  .dropdown__single-value {
    color: #35383B;
  }

  .dropdown__menu {
    width: 332px;
    max-width: 100%;
    background: #EBECED;
    margin: 0;
    margin-top: 4px;
    border-radius: 2px;
    border-color: #EBECED;
    .dropdown__option  {
      background-color: #EBECED;
    }
    .dropdown__option--is-selected{
      color: #35383B;
      background-color: rgba(91, 185, 144, 0.5);
    }
    .dropdown__option--is-focused{
      background: #5BB990;
    }
  }
  ${({ error }) => error && css`
    .dropdown__control {
      border: 1px solid red;
    }
  `}
  ${({ size }) => size && css`
    width: 234px;
    max-width: 100%;
    .dropdown__control {
      width: 234px;
      max-width: 100%;
    }
    .dropdown__menu {
      width: 234px;
      max-width: 100%;
    }
  `}
`;
const Input = styled.input`
  width: 390px;
  max-width: 100%;
  height: 52px;
  background: #EBECED;
  border: 1px solid #EBECED;
  border-radius: 2px;
  padding: 12px 16px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 17px;
  color: #5D6368;
  font-family: 'InterRegular', sans-serif;
  transition: all 0.4s ease-in-out;
  ::placeholder{
    font-size: 14px;
    line-height: 17px;
    color: #B2B6B9;
    font-family: 'InterRegular', sans-serif;
  }
  :hover{
    cursor: pointer;
    border: 1px solid #B2B6B9;
  }
  :focus{
    border: 1px solid #B2B6B9;
  }
`;

const Textarea = styled.textarea`
  width: 390px;
  max-width: 100%;
  height: 104px;
  background: #EBECED;
  border: 1px solid #EBECED;
  border-radius: 2px;
  padding: 12px 16px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 17px;
  color: #5D6368;
  font-family: 'InterRegular', sans-serif;
  ::placeholder{
    font-size: 14px;
    line-height: 17px;
    color: #B2B6B9;
    font-family: 'InterRegular', sans-serif;
  }
  :hover{
    cursor: pointer;
    border: 1px solid #B2B6B9;
  }
  :focus{
    border: 1px solid #B2B6B9;
  }
`;

const TextTerms = styled(Text10)`
  color: #35383B;
  a{
    text-decoration: underline;
    color: #35383B;
  }
  a:hover{
    color: #35383B;
  }
`;
const ButtonSubmit = styled.button`
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  height: 52px;
  width: 176px;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
  position: relative;
  svg {
    height: 52px;
    left: 0;
    position: absolute;
    top: 0; 
    width: 100%; 
  }
  rect {
    fill: none;
    stroke: ${({ color }) => color ? '#' + color : '#368D67'};
    stroke-width: 1;
    stroke-dasharray: 422, 0;
    transition: all 0.5s ease-in-out;
  }
  :hover{
    cursor: pointer;
    rect {
      stroke-width: 1;
      stroke-dasharray: 35, 310;
      stroke-dashoffset: 48;
      transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
`;
const ContainerSubmited = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
const ConfirmLogo = styled.img`
  width: 48px;
  height: 48px;
  object-fit: contain;
`;
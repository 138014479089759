import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import useLang from '../../utils/useLang';
import { Button } from '../atoms';
import { Navbar } from '../molecules';

function Page404() {

  let history = useHistory();

  return (
    <DivColumn>
      <Container404>
        <Image src='/static/images/ops.png' />
        <Text>
          Erro 404! A página que procura já não se encontra disponível.
        </Text>
        <Button text="Ir para homepage" color='236146' onClick={() => history.push('/')}/>
      </Container404>
    </DivColumn>
  )
} export default useLang(Page404);

const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  background-color: #5BB990;
  align-items: center;
  justify-content: center;
`;
const Container404 = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;
const Image = styled.img`
  width: 176px;
  height: 92px;
  margin-bottom: 4px;
`;
const Text = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  max-width: 372px;
  margin-bottom: 16px;
  text-align: center;
  font-family: 'InterRegular',sans-serif;
`;
import React, { useContext } from 'react'
import styled from 'styled-components';
import { store } from '../../../utils/store';
import { Container, H6, Section, Text12, Text16, Text18, Wrapper } from '../../atoms'
import { Intro } from '../../molecules'

export default function BannerContacts({ descriptionCompany, nameCompany, smallTitle, title }) {

  const { menu } = useContext(store);

  return (
    <Section backgroundColor="#fff">
      <Container lg>
        <Wrapper bothInitial noHeight>
          <Intro
            smallTitleColor="#368D67"
            smallTitle={smallTitle}
            titleSize="H2"
            title={title}
            titleColor="#35383B"
            titleMaxWidth="666px"
            titleFontFamily="InterRegular"
          />
          <Margin margin={5.5} />
          <H6 color="#5D6368" dangerouslySetInnerHTML={{ __html: nameCompany }} />
          <Text18 color="#5D6368" dangerouslySetInnerHTML={{ __html: descriptionCompany }} />
          <Margin margin={4.5} />
          <DivRowContacts className='desktop'>
            <DivColumn>
              <DivColumn>
                <Title color="#35383B" dangerouslySetInnerHTML={{ __html: menu?.labelPhone }} />
                <a href={`tel:${menu?.phone}`} target="__blank">
                  <Text18 color="#5D6368" dangerouslySetInnerHTML={{ __html: menu?.phone }} />
                </a>
                <Text16 color="#5D6368" dangerouslySetInnerHTML={{ __html: menu?.labelPhoneBelow }} />
              </DivColumn>
              <Margin margin={2.5} />
              <DivColumn>
                <Title color="#35383B" dangerouslySetInnerHTML={{ __html: menu?.labelEmail }} />
                <a href={`mailto:${menu?.email}`} target="__blank">
                  <Text18 color="#5D6368" dangerouslySetInnerHTML={{ __html: menu?.email }} />
                </a>
              </DivColumn>
              <Margin margin={2.5} />
              <DivColumn>
                <Title color="#35383B" dangerouslySetInnerHTML={{ __html: menu?.labelAddress }} />
                <Address color="#5D6368" maxWidth="220px" dangerouslySetInnerHTML={{ __html: menu?.address }} />
              </DivColumn>
            </DivColumn>
          </DivRowContacts>
          <DivRowContacts className='mobile'>
            <DivRow>
              <DivColumn>
                <Title color="#35383B" dangerouslySetInnerHTML={{ __html: menu?.labelPhone }} />
                <a href={`tel:${menu?.phone}`} target="__blank">
                  <Text18 color="#5D6368" dangerouslySetInnerHTML={{ __html: menu?.phone }} />
                </a>
                <Text16 color="#5D6368" dangerouslySetInnerHTML={{ __html: menu?.labelPhoneBelow }} />
              </DivColumn>
            </DivRow>
            <Margin margin={2.5} />
            <DivColumn>
              <Title color="#35383B" dangerouslySetInnerHTML={{ __html: menu?.labelEmail }} />
              <a href={`mailto:${menu?.email}`} target="__blank">
                <Text18 color="#5D6368" dangerouslySetInnerHTML={{ __html: menu?.email }} />
              </a>
            </DivColumn>
            <Margin margin={2.5} />
            <DivColumn>
              <Title color="#35383B" dangerouslySetInnerHTML={{ __html: menu?.labelAddress }} />
              <Address color="#5D6368" maxWidth="220px" dangerouslySetInnerHTML={{ __html: menu?.address }} />
            </DivColumn>
          </DivRowContacts>
        </Wrapper>
      </Container>
    </Section>

  )
}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const DivColumn = styled.div`
  display: flex;
  flex-flow: column;
`;
const DivRow = styled.div`
  display: flex;
  flex-flow: row;
`;
const DivRowContacts = styled.div`
  display: flex;
  flex-flow: row;
  > div:first-of-type{
    margin-right: 40px;
  }
  @media screen and (min-width: 701px){
    &.desktop{
      display: flex;
    }
    &.mobile{
      display: none;
    }
  }
  @media screen and (max-width: 700px){
    &.desktop{
      display: none;
    }
    &.mobile{
      display: flex;
      flex-flow: column;
    }
  }
`;
const Address = styled(Text18)`
  p:first-child{
    margin-block-start: 0;
    margin-block-end: 4px;
  }
  p:last-child{
    margin-block-start: 0;
    margin-block-end: 0;
  }
  a{
    text-decoration-line: underline;
    color: #368D67;
    font-size: 12px;
    line-height: 14px;
  }
`;
const Title = styled(Text12)`
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-family: 'InterBlack', sans-serif;
`;
const LinkMaps = styled.a`
  text-decoration-line: underline;
  color: #368D67;
  font-size: 12px;
  line-height: 14px;
`;
import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import { Container, Section, Text12, Text14, Text18, Wrapper } from '../../atoms';
import { Intro, ModalWarning } from '../../molecules';
import * as Scroll from 'react-scroll';

const LinkScroll = Scroll.Link;

export default function Banner({ smallTitle, title, descricao, img, textKnow, titleWarn, warnings, ...props }) {

  const [isOpen, setIsOpen] = useState(false);

  const textModalPrev = () => {
    if (warnings && warnings.length > 0) {
      let title = `<b>${warnings[0].title}</b>`
      let descriptionReplaced = warnings[0].descricao.replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
      let text = title.concat(" " + descriptionReplaced.toString());

      return (
        <DivRow>
          <Text18 color="#fff" dangerouslySetInnerHTML={{ __html: (text.length > 112) ? text.slice(0, 112) + "..." : text }} />
        </DivRow>
      )
    }
  }

  return (
    <Section backgroundColor="#fff" onTop>
      <>
        <ImageBackground src={img && img.url} />
        <Wrapper bothInitial full >
          <Container lg>
            <DivContainer>
              <DivIntro>
                <Intro
                  smallTitleColor="#368D67"
                  smallTitle={smallTitle}
                  titleSize="H2"
                  title={title}
                  titleColor="#fff"
                  titleMaxWidth="686px"
                  titleFontFamily="InterRegular"
                  description={descricao}
                  descriptionSize={18}
                  descriptionMaxWidth="372px"
                  descriptionColor="#fff"
                />
                <DivRow style={{ marginTop: 52 }} className="knowmore">
                  <Text14 color="#fff" dangerouslySetInnerHTML={{ __html: textKnow }} />
                  <LinkScroll
                    to='about'
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <DivArrow>
                      <img src="/static/images/arrow_down.svg" />
                    </DivArrow>
                  </LinkScroll>
                </DivRow>
              </DivIntro>
              <DivImage>
                <iframe src={process.env.REACT_APP_BACKEND_HOST + '/static/animation/animation.html'} frameBorder='0' />
                <img src="/static/images/logo_medidata_static.png" />
              </DivImage>
            </DivContainer>
          </Container>
        </Wrapper>
        {(warnings && warnings.length > 0) &&
          <>
            <DivWarning>
              <Text12 font="InterBlack" style={{ textTransform: 'uppercase' }} color="#236146" dangerouslySetInnerHTML={{ __html: titleWarn }} />
              <Margin />
              <DivRow style={{ justifyContent: 'space-between' }}>
                {textModalPrev()}
                <ButtonPlus onClick={() => setIsOpen(true)}>
                  <IconPlus src="/static/images/icon_plus.svg" />
                </ButtonPlus>
              </DivRow>
            </DivWarning>
            <ModalWarning isOpen={isOpen}>
              <ContainerWarning>
                <ButtonMinus onClick={() => setIsOpen(false)}>
                  <IconMinus src="/static/images/icon_minus.svg" />
                </ButtonMinus>
                <Text12 color="#236146" font="InterBlack" height={16} style={{ textTransform: 'uppercase' }} dangerouslySetInnerHTML={{ __html: titleWarn }} />
                <ScrollWarning>
                  {warnings.map((warning, index) => (
                    <BlockWarning key={String(index)}>
                      <Text18 color="#fff" font="InterBold" dangerouslySetInnerHTML={{ __html: warning.title }} />
                      <Margin margin={2} />
                      <Text18 color="#fff" dangerouslySetInnerHTML={{ __html: warning.descricao }} />
                    </BlockWarning>
                  ))
                  }
                </ScrollWarning>
              </ContainerWarning>
            </ModalWarning>
          </>
        }
      </>
    </Section>
  )
}

const Margin = styled.div`
  margin-top: ${({ margin }) => margin ? margin * 8 : 8}px;
`;
const ImageBackground = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media screen and (max-width:700px){
    object-position: right;
  }
`;
const DivContainer = styled.div`
  display: flex;
  flex-flow: row;
  height: 100%;
`;
const DivIntro = styled.div`
  display: flex;
  min-height: 100%;
  flex-flow: column;
  justify-content: center;
  z-index: 1;
  @media screen and (max-width:1200px){
    flex: 1;
    h2{
      max-width: 580px;
    }
  }
  @media screen and (max-width:700px){
    h2{
      max-width: 300px;
    }
  }
`;
const DivRow = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  @media screen and (max-width:700px){
    &.knowmore{
      margin-bottom: 160px;
    }
  }
`;
const DivArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background: #5BB990;
  border-radius: 26px;
  margin-left: 24px; 
  img{
    animation: bounce 0.5s; 
    animation-direction: alternate; 
    animation-timing-function: cubic-bezier(.5, 0.05, 1, .5); 
    animation-iteration-count: infinite;
    height: 13px;
    width: 12px;
    object-fit: contain;
    
    @keyframes bounce { 
      from { 
        transform: translateY(-2px); 
      } 
      to { 
        transform: translateY(2px); 
      } 
    } 
  }
`;
const DivImage = styled.div`
  position: absolute;
  top: 160px;
  right: 0;
  bottom: 160px;
  left: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  iframe{
    height: 100%;
    width: 100%;
  }
  img{
    display: none;
  }
  @media screen and (max-width: 1200px){
    right: 10%;
    left: 50%;
    iframe{
      display: none;
    }
    img{
      display: block;
      max-width: 100%;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 800px){
    img{
      display: none;
    }
  }
`;
const DivWarning = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background: #368D67;
  padding: 32px 32px 52px;
  width: 594px;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  @media screen and (max-width: 700px){
    padding: 16px 32px 26px;
  }
`;
const IconPlus = styled(ReactSVG)`
  width: 16px;
  height: 16px;
  object-fit: contain;
  svg{
    path{
      transition: all 0.5s ease-in-out;
      stroke: #5BB990;
    }
  }
`;
const IconMinus = styled(ReactSVG)`
  width: 16px;
  height: 2px;
  object-fit: contain;
  svg{
    margin-bottom: 12px;
    path{
      transition: all 0.5s ease-in-out;
      stroke: #5BB990;
    }
  }
`;
const ButtonPlus = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #5BB990;
  height: 48px;
  width: 48px;
  min-height: 48px;
  min-width: 48px;
  border-radius: 50%;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
  :hover{
    border: 1.5px solid #236146;
    ${IconPlus}{
      svg{
        path{
          stroke: #fff;
        }
      }
    }
    cursor: pointer;
  }
  @media screen and (max-width: 700px){
    margin-left: 24px;
  }
`;
const ButtonMinus = styled.div`
  position: absolute;
  top: 56px;
  right: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #5BB990;
  height: 48px;
  width: 48px;
  min-height: 48px;
  min-width: 48px;
  border-radius: 50%;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
  :hover{
    border: 1.5px solid #236146;
    ${IconMinus}{
      svg{
        path{
          stroke: #fff;
        }
      }
    }
    cursor: pointer;
  }
`;
const ContainerWarning = styled.div`
  display: flex;
  flex-flow: column;
  padding: 56px 104px 56px 34px;
  max-height: 100vh;
  box-sizing: border-box;
  position: relative;
`;
const ScrollWarning = styled.div`
  display: flex;
  flex-flow: column;
  overflow-y: scroll;
  margin-top: 24px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const BlockWarning = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid #5BB990;
  display:flex;
  flex-flow: column;
  :first-child{
    padding-top: 0;
  }
  :last-child{
    border-bottom: unset;
  }
`;